export function getPlanVersionInformationQuery (providerId, planVersionId) {
  return JSON.stringify({
    query: `query GetPlanVersion {
      getPlanVersion(
        planVersionId: "${planVersionId}"
        providerId: "${providerId}"
      ) {
        ... on PlanVersionNotFound {
          errorCode
          errorMessage
          planVersionId
        }
        ... on PlanVersion {
          providerId
          id
          plan {
            name
          }
          version
          state
          createdAt
          createdBy
          deployedAt
          template {
            ... on InitialTemplateInstance {
              json
              priority
              fee
              firstUsageFee
              purchaseFee
              period {
                periodType
                numberOfPeriods
                recurring
              }
              proratingOptions {
                alignToBillingDoM
                subscriptionPolicy
                refundPolicy
              }
              notificationPeriodsBeforeExpiration
              services {
                ratingGroupId
                priority
                fromCountriesExpr
                toCountriesExpr
                managedBalance {
                  managedBalanceTypeId
                  periodAllowance
                  rollover
                  rolloverAllowance
                  rolloverMaxAllowance
                  maxRolloverPeriods
                  chargeNewBalanceFirst
                }
                balanceTypeIds
                restrictions
                rateBalance {
                  rate {
                    ratePerRounding
                    rateMinUnits
                    taxRate
                    initialCharge
                  }
                }
                workingHours {
                  days
                  times
                }
              }
            }
          }
        }
      }
    }    
    `
  })
}

export function getMyProviderConfig () {
  return JSON.stringify({
    query: `query GetMyProviderConfig {
        getMyProviderConfig {
          ... on ProviderConfig {
            id
            allowedLifecycleTransitions
            commonName
            deleteProviderExecutionArn
            emergencyNumbers
            eventBusArn
            homeNetworks
            lifecycleStage
            limits {
              apiType
              hardLimitTps
              overHardLimitUntil
              overSoftLimitUntil
              rejectRequestsOverSoftLimit
              softLimitTps
            }
            name
          }
          ... on InvalidProviderLifecycleStage {
            errorCode
            errorMessage
            providerLifecycleStage
          }
          ... on RateLimitExceeded {
            errorCode
            errorMessage
            retryAfter
          }
          __typename
        }
      }`
  })
}

export function getSettingsQuery (providerId) {
  return JSON.stringify({
    query: `query GetSettings {
      getSettings(providerId: "${providerId}") {
        ... on Settings {
          providerId
          json
        }
      }
    }`
  })
}

export function subscribeToPlanQuery (accountId, providerId, planVersionId, planId) {
  return JSON.stringify({
    query: `mutation SubscribeToPlan {
      subscribeToPlan(
        input: {
          accountId: "${accountId}"
          providerId: "${providerId}"
          planVersionId: "${planVersionId}"
          planId: "${planId}"
        }
      ) {
        ... on SubscribeToPlanVersionValidationFailed {
          errorMessage
          errorCode
        }
        ... on InvalidField {
          errorMessage
          fieldName
          errorCode
        }
        ... on AccountNotFound {
          errorMessage
          providerId
          errorCode
          accountId
        }
        ... on PlanVersionIsNotAssignable {
          errorMessage
          providerId
          planVersionId
          errorCode
        }
        ... on PlanVersionNotFound {
          errorMessage
          planVersionId
          providerId
          errorCode
        }
        ... on SubscribeToPlanVersionPayload {
          __typename
          subscribedPlanVersion {
            to
            from
            planVersion {
              version
              state
              id
            }
          }
          account {
            customData
            id
            activePlanVersions {
              from
              to
              planVersion {
                version
                state
                id
              }
            }
            archivedPlanVersions {
              to
              from
              planVersion {
                version
                state
                id
              }
            }
            balance {
              version
              value
              customData
            }
            inactivePlanVersions {
              to
              planVersion {
                state
                version
                id
              }
              from
            }
          }
        }
      }
    }
    `
  })
}

export function createAccountQuery (accountId, providerId, creditLimit, timezone, billingDayOfMonth, longFirstBillingCycle) {
  if (!creditLimit) {
    creditLimit = 0
  }
  return JSON.stringify({
    query: `mutation CreateAccount {
      createAccount(
        input: { accountId: "${accountId}", providerId: "${providerId}", creditLimit: ${creditLimit}${creditLimit > 0 ? `, postpaid: { timezone: "${timezone}", billingDayOfMonth: ${billingDayOfMonth}, longFirstBillingCycle: ${longFirstBillingCycle === true} }` : ''} }
      ) {
        __typename
        ... on InvalidField {
          errorMessage
          fieldName
          errorCode
        }
        ... on AccountAlreadyExists {
          errorMessage
          errorCode
          accountId
        }
        ... on AccountNotFound {
          errorMessage
          accountId
          errorCode
          providerId
        }
        ... on CreateAccountPayload {
          __typename
          account {
            id
            providerId
          }
        }
        ... on BalanceTypeNotFound {
          providerId
          balanceTypeId
          errorMessage
          errorCode
        }
        ... on PostpaidFieldInPrepaidAccount {
          errorMessage
          errorCode
        }
        ... on PostpaidPropertiesRequired {
          errorMessage
          errorCode
        }
        ... on InvalidProviderLifecycleStage {
          errorMessage
          errorCode
          providerLifecycleStage
        }
        ... on RateLimitExceeded {
          providerId
          retryAfter
          errorMessage
          errorCode
        }
        ... on InternalServerError {
          errorMessage
          errorCode
        }
      }
    }
    `
  })
}

export function deleteAccountQuery (accountId, providerId) {
  return JSON.stringify({
    query: `mutation DeleteAccount {
      deleteAccount(
        input: { accountId: "${accountId}", providerId: "${providerId}" }
      ) {
        ... on AccountHasReferences {
          errorMessage
          providerId
          errorCode
          accountId
        }
        ... on AccountNotFound {
          errorMessage
          providerId
          accountId
          errorCode
        }
        ... on DeleteAccountPayload {
          __typename
          deletedAccountId
          parentAccount {
            providerId
            id
            friendsAndFamily
            customData
          }
        }
      }
    }
    `
  })
}

export function createDeviceQuery (deviceId, accountId, providerId) {
  return JSON.stringify({
    query: `mutation CreateDevice {
      createDevice(
        input: {
          accountId: "${accountId}"
          providerId: "${providerId}"
          deviceId: "${deviceId}"
        }
      ) {
        ... on CreateDevicePayload {
          __typename
          device {
            providerId
            id
            customData
            account {
              id
              providerId
            }
          }
        }
        ... on DeviceAlreadyExists {
          __typename
          errorMessage
          errorCode
          deviceId
        }
        ... on AccountNotFound {
          errorMessage
          providerId
          errorCode
          accountId
        }
        ... on InvalidField {
          errorMessage
          fieldName
          errorCode
        }
      }
    }
    `
  })
}

export function deleteDeviceQuery (deviceId, accountId, providerId) {
  return JSON.stringify({
    query: `mutation DeleteDevice {
      deleteDevice(
        input: {
          accountId: "${accountId}"
          providerId: "${providerId}"
          deviceId: "${deviceId}"
        }
      ) {
        ... on DeviceNotFound {
          errorMessage
          providerId
          errorCode
          deviceId
        }
        ... on DeleteDevicePayload {
          __typename
          deletedDeviceId
          account {
            providerId
            id
          }
        }
      }
    }
    `
  })
}

export function getDeviceQuery (deviceId, providerId) {
  return JSON.stringify({
    query: `query GetDevice {
      getDevice(
        providerId: "${providerId}",
        deviceId: "${deviceId}"
      ) {
        ... on Device {
          providerId
          id
          account {
            providerId
            id
            customData
            churnScore
            creditLimit
            parent {
              providerId
              id
              activatedAt
            }
            balances {
              balanceId
              balanceType {
                balanceTypeId
                providerId
                name
                unitType
                limit
                rateBased
                counters {
                  balanceTypeCounterId
                  name
                  period {
                    hourOfDay
                    dayOfMonth
                  }
                  isActive
                  states {
                    name
                    threshold
                    actions
                  }
                  isExclusive
                  isOnUsedBalance
                }
              }
              priority
              balanceValue
              limit
              from
              to
              total
              reserved
              used
              available
              counters {
                balanceTypeCounterId
                name
                period {
                  hourOfDay
                  dayOfMonth
                }
                isActive
                states {
                  name
                  threshold
                  actions
                }
                isExclusive
                isOnUsedBalance
              }
            }
            activePlanVersions {
              planSubscriptionId
              from
              to
              overrides {
                name
                value
              }
              planVersion {
                providerId
                createdAt
                createdBy
                deployedAt
                deployedBy
                id
                modifiedAt
                modifiedBy
                priority
                refCount
                state
                version
                plan {
                  providerId
                  name
                  id
                }
                template {
                  __typename
                  ... on InitialTemplateInstance {
                    services {
                      balanceTypeIds
                      managedBalance {
                        managedBalanceTypeId
                        periodAllowance
                        rollover
                        maxRolloverPeriods
                        rolloverAllowance
                        rolloverMaxAllowance
                        chargeNewBalanceFirst
                      }
                    }
                  }
                  ... on InitialRecurringFirstUsageTemplateInstance {
                    services {
                      balanceTypeIds
                    }
                  }
                }
                planServices {
                  providerId
                  
                  balanceTypeIds
                  managedBalanceTypeId
                  id
                  name
                  ratingGroupId
                  priority
                  providedFields {
                    name
                    dataType
                    usedByRules {
                      edges {
                        node {
                          providerId
                          name
                          id
                        }
                      }
                    }
                    customData
                  }
                  ruleSets {
                    crediting {
                      id
                      name
                      pluginId
                    }
                    recurring {
                      id
                      name
                      pluginId
                    }
                  }
                }
              }
            }
            friendsAndFamily
            activatedAt
          }
          customData
        }
        ... on DeviceNotFound {
          providerId
          deviceId
          errorCode
          errorMessage
        }
        ... on InvalidProviderLifecycleStage {
          providerLifecycleStage
          errorCode
          errorMessage
        }
        ... on RateLimitExceeded {
          retryAfter
          errorCode
          errorMessage
        }
      }
    }
    `
  })
}

export function getPlansQuery (providerId, after) {
  const variables = {
    orderBy: {
      direction: 'DESC',
      field: 'LAST_MODIFICATION_DATE'
    }
  }
  if (after) {
    variables.after = after
  }
  return JSON.stringify({
    query: `query GetPlans($orderBy: PlanOrder, $after: String) {
      getPlans(first: 25, providerId: "${providerId}", orderBy: $orderBy, after: $after) {
        edges {
          node {
            id
            name
            versions(first: 100) {
              edges {
                node {
                  version
                  state
                  id
                  createdAt
                  modifiedAt
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }`,
    variables
  })
}

export function getPlanQuery (providerId, planId) {
  return JSON.stringify({
    query: `query GetPlan {
      getPlan(
        providerId: "${providerId},
        planId: "${planId}"
      ) {
        ... on Plan {
          providerId
          id
          name
        }
        ... on PlanNotFound {
          errorMessage
          providerId
          errorCode
          planId
        }
        ... on InvalidProviderLifecycleStage {
          providerLifecycleStage
          errorCode
          errorMessage
        }
        ... on RateLimitExceeded {
          retryAfter
          errorCode
          errorMessage
        }
      }
    }`
  })
}

export function getAccountActivePlansQuery (accountId, providerId) {
  return JSON.stringify({
    query: `
        query GetAccount {
            getAccount(accountId: "${accountId}", providerId: "${providerId}") {
            ... on AccountNotFound {
              errorMessage
              providerId
              errorCode
              accountId
            }
            ... on Account {
              id
              providerId
              churnScore
              creditLimit
              postpaid {
                timezone
                billingDayOfMonth
                longFirstBillingCycle
              }
              balances {
                balanceId
                balanceType {
                  balanceTypeId
                  providerId
                  name
                  unitType
                  limit
                  rateBased
                  counters {
                    balanceTypeCounterId
                    name
                    period {
                      hourOfDay
                      dayOfMonth
                    }
                    isActive
                    states {
                      name
                      threshold
                      actions
                    }
                    isExclusive
                    isOnUsedBalance
                  }
                }
                priority
                balanceValue
                limit
                from
                to
                total
                reserved
                used
                available
                counters {
                  balanceTypeCounterId
                  name
                  period {
                    hourOfDay
                    dayOfMonth
                  }
                  isActive
                  states {
                    name
                    threshold
                    actions
                  }
                  isExclusive
                  isOnUsedBalance
                }
              }
              activePlanVersions {
                planSubscriptionId
                from
                to
                overrides {
                  name
                  value
                }
                planVersion {
                  providerId
                  createdAt
                  createdBy
                  deployedAt
                  deployedBy
                  id
                  plan {
                    id
                  }
                  modifiedAt
                  modifiedBy
                  priority
                  refCount
                  state
                  version
                  template {
                    __typename
                    ... on InitialTemplateInstance {
                      services {
                        balanceTypeIds
                        managedBalance {
                          managedBalanceTypeId
                          periodAllowance
                          rollover
                          maxRolloverPeriods
                          rolloverAllowance
                          rolloverMaxAllowance
                          chargeNewBalanceFirst
                        }
                      }
                    }
                    ... on InitialRecurringFirstUsageTemplateInstance {
                      services {
                        balanceTypeIds
                      }
                    }
                  }
                  planServices {
                    providerId
                    
                    balanceTypeIds
                    managedBalanceTypeId
                    id
                    name
                    ratingGroupId
                    priority
                    providedFields {
                      name
                      dataType
                      usedByRules {
                        edges {
                          node {
                            providerId
                            name
                            id
                          }
                        }
                      }
                      customData
                    }
                    ruleSets {
                      crediting {
                        id
                        name
                        pluginId
                      }
                      recurring {
                        id
                        name
                        pluginId
                      }
                    }
                  }
                }
              }
            }
          }
        }
        `
  })
}

export function getAccountQuery (accountId, providerId) {
  return JSON.stringify({
    query: `
        query GetAccount {
            getAccount(accountId: "${accountId}", providerId: "${providerId}") {
            ... on AccountNotFound {
              errorMessage
              providerId
              errorCode
              accountId
            }
            ... on Account {
              id
              customData
              providerId
              churnScore
              creditLimit
              postpaid {
                timezone
                billingDayOfMonth
                longFirstBillingCycle
              }
              balance {
                customData
                value
                version
              }
              balances {
                balanceId
                balanceType {
                  balanceTypeId
                  providerId
                  name
                  unitType
                  limit
                  rateBased
                  counters {
                    balanceTypeCounterId
                    name
                    period {
                      hourOfDay
                      dayOfMonth
                    }
                    isActive
                    states {
                      name
                      threshold
                      actions
                    }
                    isExclusive
                    isOnUsedBalance
                  }
                }
                priority
                balanceValue
                limit
                from
                to
                total
                reserved
                used
                available
                counters {
                  balanceTypeCounterId
                  name
                  period {
                    hourOfDay
                    dayOfMonth
                  }
                  isActive
                  states {
                    name
                    threshold
                    actions
                  }
                  isExclusive
                  isOnUsedBalance
                }
              }
              activePlanVersions {
                planSubscriptionId
                from
                to
                overrides {
                  name
                  value
                }
                planVersion {
                  providerId
                  createdAt
                  createdBy
                  deployedAt
                  deployedBy
                  id
                  modifiedAt
                  modifiedBy
                  priority
                  refCount
                  state
                  version
                  plan {
                    providerId
                    name
                    id
                  }
                  template {
                    __typename
                    ... on InitialTemplateInstance {
                      services {
                        balanceTypeIds
                        managedBalance {
                          managedBalanceTypeId
                          periodAllowance
                          rollover
                          maxRolloverPeriods
                          rolloverAllowance
                          rolloverMaxAllowance
                          chargeNewBalanceFirst
                        }
                      }
                    }
                    ... on InitialRecurringFirstUsageTemplateInstance {
                      services {
                        balanceTypeIds
                      }
                    }
                  }
                  planServices {
                    providerId
                    
                    balanceTypeIds
                    managedBalanceTypeId
                    id
                    name
                    ratingGroupId
                    priority
                    providedFields {
                      name
                      dataType
                      usedByRules {
                        edges {
                          node {
                            providerId
                            name
                            id
                          }
                        }
                      }
                      customData
                    }
                    ruleSets {
                      crediting {
                        id
                        name
                        pluginId
                      }
                      recurring {
                        id
                        name
                        pluginId
                      }
                    }
                  }
                }
              }
              archivedPlanVersions {
                from
                to
                overrides {
                  name
                  value
                }
                planVersion {
                  createdAt
                  createdBy
                  deployedAt
                  deployedBy
                  id
                  modifiedAt
                  modifiedBy
                  priority
                  refCount
                  state
                  version
                  plan {
                    providerId
                    name
                    id
                  }
                  planServices {
                    providerId
                    
                    balanceTypeIds
                    managedBalanceTypeId
                    id
                    name
                    ratingGroupId
                    priority
                    providedFields {
                      name
                      dataType
                      usedByRules {
                        edges {
                          node {
                            providerId
                            name
                            id
                          }
                        }
                      }
                      customData
                    }
                    ruleSets {
                      crediting {
                        id
                        name
                        pluginId
                      }
                      recurring {
                        id
                        name
                        pluginId
                      }
                    }
                  }
                }
              }
              inactivePlanVersions {
                from
                to
                planVersion {
                  createdAt
                  createdBy
                  deployedAt
                  deployedBy
                  id
                  modifiedAt
                  modifiedBy
                  priority
                  refCount
                  state
                  version
                  planServices {
                    providerId
                    
                    balanceTypeIds
                    managedBalanceTypeId
                    id
                    name
                    ratingGroupId
                    priority
                    providedFields {
                      name
                      dataType
                      usedByRules {
                        edges {
                          node {
                            providerId
                            name
                            id
                          }
                        }
                      }
                      customData
                    }
                    ruleSets {
                      crediting {
                        id
                        name
                        pluginId
                      }
                      recurring {
                        id
                        name
                        pluginId
                      }
                    }
                  }
                }
              }
            }
          }
        }
        `
  })
}

export function creditAccountMutation (accountId, providerId, amount) {
  return JSON.stringify({
    query: `mutation credit {
      creditAccount(
        input: { accountId: "${accountId}", providerId: "${providerId}", amount: ${amount} }
      ) {
        ... on InvalidField {
          errorMessage
          fieldName
          errorCode
        }
        ... on CreditAccountPayload {
          __typename
          account {
            id
            providerId
            balance {
              customData
            }
          }
        }
      }
    }
    `
  })
}

export function getEventDataRecordsByAccountQuery (providerId, accountId, types, after, fromTimestamp) {
  let query
  if (!types) {
    types = 'BILLING'
  }
  query = `query getEventDataRecordsByAccount {
    getEventDataRecordsByAccount(
      providerId: "${providerId}",
      accountId: "${accountId}",`
  if (after) {
    query += `after: "${after}",`
  }
  query += `first: 25,
      filterBy: {
        types: [${types}],
        from: "${fromTimestamp}"
    })
    {
        ... on EventDataRecordAccountConnection{
              edges{
                  node{
                      providerId
                      action
                      eventData
                      customData
                      createdAt
                      id
                  }
                  cursor
              }
              pageInfo{
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
              }
          }
          ... on AccountNotFound{
              providerId
              accountId
              errorCode
              errorMessage
          }
      }
    }`
  return JSON.stringify({
    query: query
  })
}

export function getEventDataRecordsByDeviceQuery (providerId, deviceId, timestampStart, timestampEnd, after) {
  let query
  query = `query getEventDataRecordsByDevice {
    getEventDataRecordsByDevice(
      providerId: "${providerId}",
      deviceId: "${deviceId}",`
  if (after !== undefined) {
    query += `after: "${after}",`
  }
  query += `first: 10,
      filterBy: {
        types: [BILLING, CHARGING],
        from: "${timestampStart}",
        to: "${timestampEnd}"
    })
    {
        ... on EventDataRecordDeviceConnection{
              edges{
                  node{
                      providerId
                      action
                      eventData
                      customData
                      createdAt
                      id
                  }
                  cursor
              }
              pageInfo{
              hasPreviousPage
              hasNextPage
              startCursor
              endCursor
              }
          }
          ... on DeviceNotFound{
              providerId
              deviceId
              errorCode
              errorMessage
          }
      }
    }`
  return JSON.stringify({
    query: query
  })
}
export function CancelPlanSubscriptionByPlanVersionMutation (accountId, providerId, planVersionId) {
  return JSON.stringify({
    query: `mutation CancelPlanSubscription {
      cancelPlanSubscription(
        input: {
          accountId: "${accountId}"
          providerId: "${providerId}"
          planVersionId: "${planVersionId}"
        }
      ) {
        ... on SubscriptionNotFound {
          from
          to
          planVersionId
          errorMessage
          errorCode
        }
        ... on InvalidField {
          errorMessage
          fieldName
          errorCode
        }
        ... on AccountNotFound {
          errorMessage
          providerId
          errorCode
          accountId
        }
        ... on PlanVersionNotFound {
          providerId
          planVersionId
          errorMessage
          errorCode
        }
        ... on InternalServerError {
          errorCode
          errorMessage
        }
        ... on InvalidProviderLifecycleStage {
          errorCode
          errorMessage
          providerLifecycleStage
        }
        ... on MultipleSubscriptions {
          planVersionId
          errorCode
          errorMessage
        }
        ... on CancelPlanVersionSubscriptionPayload {
          __typename
          account {
            providerId
            id
            customData
            activePlanVersions {
              to
              from
              planVersion {
                version
                state
                id
              }
            }
            archivedPlanVersions {
              to
              from
              planVersion {
                version
                state
                id
              }
            }
            inactivePlanVersions {
              to
              from
              planVersion {
                version
                state
                id
              }
            }
          }
        }
      }
    }
    `
  })
}

export function CancelPlanSubscriptionByPlanSubscriptionMutation (accountId, providerId, planSubscriptionId) {
  return JSON.stringify({
    query: `mutation CancelPlanSubscription {
      cancelPlanSubscription(
        input: {
          accountId: "${accountId}"
          providerId: "${providerId}"
          planSubscriptionId: "${planSubscriptionId}"
        }
      ) {
        ... on SubscriptionNotFound {
          from
          to
          planVersionId
          errorMessage
          errorCode
        }
        ... on InvalidField {
          errorMessage
          fieldName
          errorCode
        }
        ... on AccountNotFound {
          errorMessage
          providerId
          errorCode
          accountId
        }
        ... on PlanVersionNotFound {
          providerId
          planVersionId
          errorMessage
          errorCode
        }
        ... on InternalServerError {
          errorCode
          errorMessage
        }
        ... on InvalidProviderLifecycleStage {
          errorCode
          errorMessage
          providerLifecycleStage
        }
        ... on MultipleSubscriptions {
          planVersionId
          errorCode
          errorMessage
        }
        ... on CancelPlanVersionSubscriptionPayload {
          __typename
          account {
            providerId
            id
            customData
            activePlanVersions {
              to
              from
              planVersion {
                version
                state
                id
              }
            }
            archivedPlanVersions {
              to
              from
              planVersion {
                version
                state
                id
              }
            }
            inactivePlanVersions {
              to
              from
              planVersion {
                version
                state
                id
              }
            }
          }
        }
      }
    }
    `
  })
}

export function getCurrentUserQuery () {
  return JSON.stringify({
    query: `query GetCurrentUser {
      getCurrentUser {
        providerId
        userId
        email
        name
        roleGroupMemberships
        phoneNumber
        jobTitle
        softwareMfaEnabled
      }
    }`
  })
}

export function updateProviderConfigEventBusArn (eventBusArn) {
  return JSON.stringify({
    query: `mutation updateMyProviderConfig {
      updateMyProviderConfig(
        input: {
          eventBusArn: "${eventBusArn}"
        }
      ) {
        ... on ProviderConfig {
          commonName
          eventBusArn
          homeNetworks
          emergencyNumbers
        }
        ... on InvalidProviderLifecycleStage {
          errorCode
          errorMessage
          providerLifecycleStage
        }
        ... on InvalidField {
          fieldName
          errorCode
          errorMessage
        }
      }
    }`
  })
}

export function getFieldMappingsQuery (providerId, first) {
  return JSON.stringify({
    query: `query GetFieldMappings {
      getFieldMappings(
        providerId: "${providerId}",
        first: ${first}
      ) {
        ... on FieldMappingConnection {
          edges {
            node {
              path
              expr
              field {
                name
                dataType
                customData
              }
            }
            cursor
          }
          pageInfo {
            endCursor
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }`
  })
}

export function getRatingGroupHierarchyQuery (providerId) {
  return JSON.stringify({
    query: `
      query GetRatingGroupHierarchy {
        getRatingGroupHierarchy(providerId: "${providerId}") {
          ... on RatingGroup {
            providerId
            id
            name
            perUnitRounding
            minReservation
            maxReservation
            unitType
            quotaValidityTime
            quotaHoldingTime
            enableDynamicQuotaAllocation
            isDefault
            children {
              id
              name
              perUnitRounding
              minReservation
              maxReservation
              unitType
              quotaValidityTime
              quotaHoldingTime
              enableDynamicQuotaAllocation
              isDefault
              children {
                id
                name
                perUnitRounding
                minReservation
                maxReservation
                unitType
                quotaValidityTime
                quotaHoldingTime
                enableDynamicQuotaAllocation
                isDefault
              }
            }
          }
          ... on InvalidProviderLifecycleStage {
            providerLifecycleStage
            errorCode
            errorMessage
          }
          ... on RateLimitExceeded {
            providerId
            retryAfter
            errorCode
            errorMessage
          }
          ... on InternalServerError {
            errorCode
            errorMessage
          }
        }
      }
    `
  })
}

export function getBalanceTypesQuery (providerId) {
  return JSON.stringify({
    query: `
      query GetBalanceTypes {
        getBalanceTypes(getBalanceTypesInput: { providerId: "${providerId}" }) {
          ... on GetBalanceTypesPayload {
              balanceTypes {
                  balanceTypeId
                  providerId
                  name
                  unitType
                  limit
                  rateBased
                  counters {
                      balanceTypeCounterId
                      name
                      period {
                          type
                          hourOfDay
                          dayOfMonth
                      }
                      isActive
                      states {
                          name
                          threshold
                          actions
                      }
                      isExclusive
                      isOnUsedBalance
                  }
              }
          }
          ... on BalanceTypeNotFound {
              providerId
              balanceTypeId
              errorCode
              errorMessage
          }
          ... on RateLimitExceeded {
              providerId
              retryAfter
              errorCode
              errorMessage
          }
          ... on InternalServerError {
              errorCode
              errorMessage
          }
        }
      }
    `
  })
}
