export const subscribeToPlan = /* GraphQL */ `
  mutation SubscribeToPlan($input: SubscribeToPlanVersionInput!) {
    subscribeToPlan(input: $input) {
      ... on SubscribeToPlanVersionValidationFailed {
        errorMessage
        errorCode
      }
      ... on InvalidField {
        errorMessage
        fieldName
        errorCode
      }
      ... on AccountNotFound {
        errorMessage
        providerId
        errorCode
        accountId
      }
      ... on PlanVersionIsNotAssignable {
        errorMessage
        providerId
        planVersionId
        errorCode
      }
      ... on PlanVersionNotFound {
        errorMessage
        planVersionId
        providerId
        errorCode
      }
      ... on SubscribeToPlanVersionPayload {
        __typename
        subscribedPlanVersion {
          to
          from
          planVersion {
            version
            state
            id
          }
        }
        account {
          customData
          id
          activePlanVersions {
            from
            to
            planVersion {
              plan {
                name
              }
              version
              state
              id
            }
          }
          archivedPlanVersions {
            to
            from
            planVersion {
              plan {
                name
              }
              version
              state
              id
            }
          }
          inactivePlanVersions {
            to
            planVersion {
              plan {
                name  
              }
              state
              version
              id
            }
            from
          }
        }
      }
    }
  }
`

export const updateBalanceMutation = /* GraphQL */ `
  mutation UpdateBalance($input: UpdateBalanceInput!) {
    updateBalance(input: $input) {
      ... on BalancePayload {
        providerId
        accountId
        balanceInfo {
          balanceId
          balanceType {
            balanceTypeId
            name
            limit
          }
          priority
          balanceValue
          limit
          used
          available
        }
      }
      ... on BalanceNotFound {
        providerId
        balanceId
        errorCode
        errorMessage
      }
      ... on BalanceTypeNotFound {
        providerId
        balanceTypeId
        errorCode
        errorMessage
      }
      ... on AccountNotFound {
        providerId
        accountId
        errorCode
        errorMessage
      }
      ... on InvalidField {
        fieldName
        errorCode
        errorMessage
      }
      ... on RateLimitExceeded {
        providerId
        retryAfter
        errorCode
        errorMessage
      }
      ... on InternalServerError {
        errorCode
        errorMessage
      }
    }
  }
`
